<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1"
        @click="visible = !visible"
      >
        {{ $t("key-10") }}
      </b-button>

      <b-collapse id="collapse-1" v-model="visible">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t("addnewdata") }}
            </h3>
          </div>
          <div class="p-2 row">
            <b-row>
              <b-col md="7">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="4"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <p
                        v-if="
                          arr_parcelCode &&
                          arr_parcelCode.lot_name &&
                          arr_parcelCode.lot_show === 1
                        "
                        class="text-warning"
                      >
                        {{ $t("haveiteminlot") }} {{ arr_parcelCode.lot_name }}
                      </p>
                      <p v-else class="text-warning">
                        {{ $t("addnewdata") }}
                      </p>
                    </b-form-group>
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols="4"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("parcelCode") }}
                        <span class="text-danger">*</span>
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="parcel-code"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <i class="fal fa-barcode-read" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="h-parcel-code"
                            v-model="parcelCode"
                            :state="errors.length > 0 ? false : null"
                            @input="checkInput"
                            @change="SearchCode(parcelCode)"
                          />
                        </b-input-group>
                        <span class="text-danger">{{
                          errors[0] ? "กรุณากรอกรหัสพัสดุ" : ""
                        }}</span>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="h-order-list"
                      label-cols="4"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("orderList") }}
                      </template>

                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <i class="far fa-cart-arrow-down" />
                        </b-input-group-prepend>
                        <b-form-input id="h-order-list" v-model="orderList" />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      label-for="h-number"
                      label-cols="2"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label> {{ $t("sequence") }} </template>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          id="h-number"
                          v-model="ordernumber"
                          type="Number"
                          class="w-50"
                        />
                        <button
                          type="button"
                          class="btn btn-primary ml-50"
                          @click="
                            ordernumber =
                              Orderall.reduce(
                                (acc, total) => acc + total.product_amount,
                                0
                              ) + 1
                          "
                        >
                          {{ $t("key-115") }}
                        </button>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-for="h-amount"
                      label-cols="2"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("quantity") }} <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="product-amount"
                        rules="required"
                      >
                        <b-form-input
                          id="h-amount"
                          v-model="amount"
                          type="number"
                          class="w-50"
                          :state="errors.length > 0 ? false : null"
                          step="1"
                          @input="reitem()"
                        />
                        <span class="text-danger">{{
                          errors[0] ? "กรุณากรอกจำนวน" : ""
                        }}</span>
                      </validation-provider>
                    </b-form-group>
                    <!-- อันนี้คือประเภทสินค้า -->
                    <b-form-group
                      label-for="h-product-type"
                      label-cols="2"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("productType") }}
                        <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="product-type"
                        rules="required"
                      >
                        <v-select
                          id="h-product-type"
                          v-model="productType"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="ProductTypeitem"
                          :label="Checkname()"
                          class="w-50 mr-1"
                          @input="reitem()"
                        />
                        <span class="text-danger">{{
                          errors[0] ? $t("key-82") : ""
                        }}</span>
                      </validation-provider>
                    </b-form-group>

                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-wooden-crate"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("woodPacking") }} :
                          </template>
                          <b-form-checkbox
                            id="h-wooden-crate"
                            v-model="woodenCrate"
                            name="check-buttons"
                            :value="true"
                            :unchecked-value="false"
                            switch
                            inline
                            class="mt-50"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-check-product"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("checkOrder") }} :
                          </template>
                          <b-form-checkbox
                            id="h-check-product"
                            v-model="checkProduct"
                            name="check-buttons"
                            :value="true"
                            :unchecked-value="false"
                            switch
                            inline
                            class="mt-50"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-weight"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <!-- น้ำหนัก -->
                          <template v-slot:label>
                            {{ $t("weight") }}
                            <span class="text-danger">*</span>
                          </template>
                          <validation-provider
                            #default="{ errors }"
                            name="product-weight"
                            rules="required"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-product-weight"
                                v-model="productWeight"
                                type="number"
                                placeholder="0"
                                :state="errors.length > 0 ? false : null"
                                @input="checkedprice()"
                              />
                              <b-input-group-append is-text>
                                {{ $t("key-116") }}
                              </b-input-group-append>
                            </b-input-group>
                            <span class="text-danger">{{
                              errors[0] ? "กรุณากรอกน้ำหนัก" : ""
                            }}</span>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-height"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <!-- ความสูง -->

                          <template v-slot:label>
                            {{ $t("height") }}
                            <span class="text-danger">*</span>
                          </template>
                          <validation-provider
                            #default="{ errors }"
                            name="product-height"
                            rules="required"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-product-height"
                                v-model="productHeight"
                                type="number"
                                placeholder="0"
                                :state="errors.length > 0 ? false : null"
                                @input="checkedpricereq()"
                              />
                              <b-input-group-append is-text>
                                {{ $t("cm") }}
                              </b-input-group-append>
                            </b-input-group>
                            <span class="text-danger">{{
                              errors[0] ? "กรุณากรอกความสูง" : ""
                            }}</span>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-width"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("widthCm") }}
                            <span class="text-danger">*</span>
                          </template>
                          <validation-provider
                            #default="{ errors }"
                            name="product-width"
                            rules="required"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-product-width"
                                v-model="productWidth"
                                type="number"
                                placeholder="0"
                                :state="errors.length > 0 ? false : null"
                                @input="checkedpricereq()"
                              />
                              <b-input-group-append is-text>
                                {{ $t("cm") }}
                              </b-input-group-append>
                            </b-input-group>
                            <span class="text-danger">{{
                              errors[0] ? "กรุณากรอกความกว้าง" : ""
                            }}</span>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-long"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("lengthCm") }}
                            <span class="text-danger">*</span>
                          </template>
                          <validation-provider
                            #default="{ errors }"
                            name="product-long"
                            rules="required"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                id="h-product-long"
                                v-model="productLong"
                                type="number"
                                placeholder="0"
                                :state="errors.length > 0 ? false : null"
                                @input="checkedpricereq()"
                              />
                              <b-input-group-append is-text>
                                {{ $t("cm") }}
                              </b-input-group-append>
                            </b-input-group>
                            <span class="text-danger">{{
                              errors[0] ? "กรุณากรอกความยาว" : ""
                            }}</span>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <hr />
                    <b-row class="pt-1">
                      <b-col md="6">
                        <b-form-group
                          label-for="h-check-product-price"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("checkOrder") }}
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-check-product-price"
                              v-model="checkProductPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-trans-price"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("key-114") }}
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-trans-price"
                              v-model="transPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-new-wrap"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("repackage") }}
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-new-wrap"
                              v-model="newWrap"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-wooden-price"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("cratePricing") }}
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-wooden-price"
                              v-model="woodenPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-other"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t("other") }}
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-other"
                              v-model="other"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" />
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-file1"
                      label-cols="4"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("shippingAttachment") }} :
                      </template>
                      <b-form-file
                        id="h-file1"
                        v-model="filesp"
                        accept=".jpg, .png, .jpeg"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="fileProduct"
                      />
                      <div class="mt-2 d-flex justify-content-left">
                        <span
                          v-for="images in filesProducts"
                          :key="images"
                          class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                        >
                          <img
                            :src="GetImg('postnumber_file', images)"
                            accept=".jpg, .png, .jpeg"
                            alt="รูปภาพ"
                            class=""
                          />
                          <div class="preview-image-overlays p-0">
                            <i
                              class="fas fa-times-circle cursor-pointer"
                              @click="deleteorderfile(images)"
                            />
                          </div>
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-for="h-files-name"
                      label-cols="4"
                      label-cols-lg="2"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        {{ $t("key-117") }}
                      </template>
                      <b-form-file
                        v-model="orderFiles"
                        accept=".jpg, .png, .jpeg"
                        multiple
                        @change="orderImage"
                      />
                      <div class="mt-2 d-flex justify-content-left">
                        <span
                          v-for="imageOr in orderImages"
                          :key="imageOr"
                          class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                        >
                          <img
                            :src="GetImg('postnumber_img', imageOr)"
                            alt="Preview"
                            class=""
                          />
                          <div class="preview-image-overlays p-0">
                            <i
                              class="fas fa-times-circle cursor-pointer"
                              @click="deleteImageorder(imageOr)"
                            />
                          </div>
                        </span>
                      </div>

                      <!-- <span
                        v-for="imageOr in orderImages"
                        :key="imageOr"
                        class="od-pc-offer-price-common "
                      >
                        <img
                          :src="imageOr"
                          accept=".jpg, .png, .jpeg"
                          alt="รูปภาพ"
                          class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                        >

                        <button
                          class="btn btn-gradient-danger"
                          @click="deleteImageorder(imageOr)"
                        >
                          ลบ
                        </button>
                      </span> -->
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="5">
                <b-form-group
                  label-for="h-customerCode"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <!-- อันนี้คือรหัสลูกค้า -->
                  <template v-slot:label>
                    <span class="text-danger">*</span>
                    {{ $t("key-118") }}
                    <span class="text-primary">
                      <i
                        v-b-tooltip.hover.top="'Copy'"
                        v-clipboard:copy="
                          customerCode ? customerCode.username : ''
                        "
                        :class="iconClasscustomer"
                        @click="handleCopycustomer"
                      />
                    </span>
                  </template>
                  <v-select
                    id="h-customerCode"
                    v-model="customerCode"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="username"
                    :options="customerCodeList"
                    class="w-100 mr-1"
                    @input="
                      getProduct(customerCode.id),
                        (productType = null),
                        (customerAgent = null)
                    "
                  />
                </b-form-group>

                <b-form-group
                  v-if="checkagents"
                  label-for="h-customeragent "
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t("memberCode") }}
                    <span class="text-primary">
                      <i
                        v-b-tooltip.hover.top="'Copy'"
                        v-clipboard:copy="
                          customerAgent ? customerAgent.user_member : ''
                        "
                        class="cursor-pointer"
                        :class="iconClass"
                        @click="handleCopy"
                      />
                    </span>
                  </template>
                  <v-select
                    id="h-customeragent"
                    v-model="customerAgent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="user_member"
                    :options="customerAgentList"
                    class="w-100 mr-1"
                  />
                </b-form-group>

                <b-form-group
                  label-for="h-remark-new"
                  label-cols="3"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label> {{ $t("remarks") }} : </template>
                  <b-form-textarea
                    id="h-remark-new"
                    v-model="remarkCode"
                    type="text"
                  />
                </b-form-group>

                <b-form-group
                  label-for="h-file1"
                  label-cols="4"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label> {{ $t("key-326") }} </template>
                  <div class="h5 mb-0 mt-50">
                    {{ checksave(Commas(charge_per_save)) }} ฿
                    <!-- {{ checksave(quantity_cue) }} ฿ -->
                  </div>
                </b-form-group>

                <b-form-group
                  label-for="h-select-price"
                  label-cols="4"
                  label-cols-lg="3"
                  label-cols-md="3"
                >
                  <template v-slot:label> {{ $t("key-121") }} </template>
                  <div>
                    <b-form-radio
                      v-model="selectPrice"
                      name="some-selectPrice"
                      :value="1"
                      class="custom-control-primary mb-50 mt-50"
                    >
                      {{ $t("key-175") }}
                      {{ checkvalue(Commas(charge_per_kilo)) }} ฿
                    </b-form-radio>

                    <b-form-radio
                      v-model="selectPrice"
                      name="some-selectPrice"
                      :value="2"
                      class="custom-control-primary"
                    >
                      {{ $t("key-0") }}
                      {{ checkvalue(Commas(charge_per_cue)) }} ฿
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-form-group
                label-for="h-parcel-code"
                label-cols-md="6"
                class="ml-5"
              >
                <div class="d-flex align-items-center">
                  <button
                    class="btn btn-gradient-primary mb-1"
                    :disabled="checkedparcelCode === 0"
                    @click="SubmitOrder()"
                  >
                    {{ $t("saveData") }}
                  </button>
                  <button
                    class="btn btn-gradient-danger mb-1 ml-2"
                    @click="Orderdel()"
                  >
                    {{ $t("key") }}
                  </button>
                </div>
              </b-form-group>
            </b-row>
          </div>
        </div>
      </b-collapse>

      <b-row class="match-height">
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">{{ $t("key-1") }} LOT-{{ items.number_lot }}</h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-for="h-product-name"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("orderNumber") }} :
                        </template>
                        <div id="h-product-name">
                          LOT- {{ items.number_lot }}
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-name"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("firstName") }} :
                        </template>
                        <b-form-input id="h-name" v-model="items.name_lot" />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-remark"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("remarks") }} :
                        </template>
                        <b-form-textarea
                          id="h-remark"
                          v-model="items.remark"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-tran-type"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label> {{ $t("key-2") }} : </template>

                        <div class="demo-inline-spacing mt-n1">
                          <b-form-radio
                            v-model="items.transportType"
                            name="some-radios"
                            value="1"
                          >
                            {{ $t("truck") }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="items.transportType"
                            name="some-radios"
                            value="2"
                          >
                            {{ $t("ship") }}
                          </b-form-radio>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-discount"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("discount") }}(%) :
                        </template>
                        <b-form-input
                          id="h-discount"
                          v-model="items.discount"
                          type="number"
                          class="w-25"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-amount-export"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("exportedAmount") }} :
                        </template>
                        <b-form-input
                          id="h-amount-export"
                          v-model="items.amount_out"
                          type="number"
                          class="w-25"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-file123"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("attachedFile") }} :
                        </template>
                        <b-form-file
                          id="h-file123"
                          v-model="filepath"
                          accept=".xlsx, .xls ,.pdf "
                          placeholder="เลือกไฟล์"
                          drop-placeholder="เลือกไฟล์"
                          @change="previewShow"
                        />
                        <span
                          v-for="filess in viewfiles"
                          :key="filess"
                          class="od-pc-offer-price-common"
                        >
                          <a @click="checkedfile(filess.key_filepath)">
                            {{ filess.namefile }}
                          </a>
                          <button
                            class="btn btn-gradient-danger"
                            @click="deleteFile(filess)"
                          >
                            {{ $t("key-3") }}
                          </button>
                        </span>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-files-picture"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("key-4") }}
                        </template>
                        <b-form-file
                          id="h-files-picture"
                          v-model="selectedFiles"
                          accept=".jpg, .png, .jpeg"
                          :state="Boolean(selectedFiles.length)"
                          :drop-placeholder="$t('key-4')"
                          :placeholder="$t('key-4')"
                          multiple
                          @change="previewImage"
                        />
                        <span
                          v-for="imagePr in previewImages"
                          :key="imagePr"
                          class="od-pc-offer-price-common"
                        >
                          <img
                            :src="imagePr.img_base64"
                            accept=".jpg, .png, .jpeg"
                            alt="รูปภาพ"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          />

                          <button
                            class="btn btn-gradient-danger"
                            @click="deleteImage(imagePr)"
                          >
                            {{ $t("key-5") }}
                          </button>
                        </span>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="12">
                      <b-form-group
                        label-for="h-files-picture"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <button
                            class="btn btn-gradient-primary mb-1 "
                            @click="Submit()"
                          >
                            {{ $t('saveData') }}
                          </button>
                        </template>
                      </b-form-group>

                    </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
              <button
                class="btn btn-gradient-primary mb-1 float-right"
                @click="Submit()"
              >
                {{ $t("saveData") }}
              </button>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="card">
        <div class="header-cardx p-2">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="mb-0">
              {{ $t("parcelCodeList") }}
            </h3>
          </div>
        </div>

        <div class="card">
          <div
            class="row p-2 d-lg-flex align-items-center justify-content-start"
          >
            <div class="col-sm-12 col-lg-3">
              <v-select
                id="h-status"
                v-model="status"
                label="text"
                :options="statuslist"
                class="w-100 mr-50"
              />
            </div>
            <div class="col-sm-12 col-lg-3">
              <b-form-datepicker
                id="example-received"
                v-model="receivedDate"
                locale="th"
                class="w-100 mr-50 mt-1 mt-lg-0 mt-md-0"
              />
            </div>
            <div class="col-sm-12 col-lg-6 mt-1 mt-lg-0 mt-md-0">
              <b-button
                variant="gradient-success"
                class="mr-50"
                :disabled="checkedCount === 0"
                @click="submitCode()"
              >
                <i class="fal fa-download" /> {{ checkedsaves(checkedCount)
                }}{{ $t("save") }}
              </b-button>

              <b-button
                variant="gradient-danger"
                class="mr-50"
                :disabled="checkedCount === 0"
                @click="resettime()"
              >
                <i class="fal fa-redo" /> {{ checkedsaves(checkedCount)
                }}{{ $t("key-6") }}
              </b-button>

              <b-button
                variant="gradient-primary"
                class="mr-50"
                @click="printLot()"
              >
                <i class="fal fa-print" /> {{ $t("print") }}
              </b-button>

              <b-button
                variant="gradient-primary"
                class="mr-50 mt-1 mt-lg-0 mt-md-0"
                @click="printOrder()"
              >
                <i class="fal fa-file-invoice" /> {{ $t("key-7") }}
              </b-button>
            </div>
          </div>

          <div class="p-2 table-container">
            <table
              id="customers"
              class="tables table-responsives"
              style="width: 100% !important; min-width: 1500px !important"
              width="100%"
            >
              <thead>
                <tr style="font-weight: 700 !important; font-size: 12px">
                  <th scope="col">
                    <template>
                      <b-form-checkbox
                        v-model="Checked"
                        type="checkbox"
                        @change="toggleCheckedall(Checked)"
                      />
                    </template>
                  </th>
                  <!-- <th scope="col">
                    {{ $t('manage') }}
                  </th> -->
                  <th scope="col">
                    {{ $t("sequence") }}
                  </th>
                  <th scope="col">
                    {{ $t("parcelCode") }}
                  </th>
                  <!-- <th scope="col">
                    {{ $t('mnemonic') }}
                  </th> -->
                  <th scope="col">
                    {{ $t("arrivedChinaWarehouse") }}
                  </th>
                  <th scope="col">
                    {{ $t("leftChinaWarehouse") }}
                  </th>
                  <th scope="col">
                    {{ $t("arrivedThaiWarehouse") }}
                  </th>
                  <!-- <th scope="col">
                    ออกโกดังไทย
                  </th> -->
                  <th scope="col">
                    {{ $t("customerCode") }}
                  </th>
                  <th scope="col">
                    {{ $t("quantity") }}
                  </th>
                  <th scope="col">
                    {{ $t("weight") }}
                  </th>
                  <th scope="col">
                    {{ $t("widthCm") }}
                  </th>
                  <th scope="col">
                    {{ $t("heightCm") }}
                  </th>
                  <th scope="col">
                    {{ $t("lengthCm") }}
                  </th>
                  <th scope="col">
                    {{ $t("cubic") }}
                  </th>
                  <th scope="col">
                    {{ $t("type") }}
                  </th>
                  <th scope="col">
                    {{ $t("arrange") }}
                  </th>
                  <th scope="col">
                    {{ $t("checkOrder") }}
                  </th>
                  <th scope="col">
                    {{ $t("repackage") }}
                  </th>
                  <th scope="col">
                    {{ $t("key-114") }}
                  </th>
                  <th scope="col">
                    {{ $t("other") }}
                  </th>
                  <th scope="col">
                    {{ $t("key-8") }}
                  </th>
                  <th scope="col">
                    {{ $t("pricePerKilo") }}
                  </th>
                  <th scope="col">
                    {{ $t("pricePerCubic") }}
                  </th>
                </tr>
              </thead>
              <thead class="text-left" style="font-size: 12px !important">
                <tr id="print-only">
                  <th />
                  <th />
                  <!-- <th /> -->
                  <th />
                  <th />
                  <th />
                  <th />

                  <!-- colspan="9" -->
                  <th>
                    <!-- {{ $t('total') }} -->
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countproducts()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countweight()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countwidth()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countheight()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countproductLong()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ CommasCue(countquantity()) }}
                  </th>
                  <th scope="col" />
                  <th scope="col" />
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countinspection()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countwrap()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutshipping()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutother()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutpackaging()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutkilo()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutcue()) }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in Orderall"
                  :key="index"
                  :class="`ctx-${checkedcolor(item)}`"
                >
                  <th scope="col" width="70">
                    <div class="d-flex">
                      <template>
                        <b-form-checkbox
                          v-model="isChecked[index]"
                          @change="toggleChecked(isChecked[index], index)"
                        />
                        <!-- <div>
                        <input
                          v-model="isChecked[index]"
                          type="checkbox"
                          @change="isCheckeds(item)"
                        >
                      </div> -->
                      </template>
                      <feather-icon
                        icon="Edit3Icon"
                        style="margin-right: 4px"
                        class="text-primary cursor-pointer"
                        @click="SearchCode(item.postnumber)"
                      />

                      <feather-icon
                        class="text-danger cursor-pointer"
                        icon="TrashIcon"
                        @click="DeleteData(item._id)"
                      />
                    </div>
                  </th>
                  <!-- <th scope="col">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="SearchCode(item.postnumber)"
                    />

                    <feather-icon
                      class="text-danger cursor-pointer"
                      icon="TrashIcon"
                      @click="DeleteData(item._id)"
                    />
                  </th> -->
                  <th scope="col" class="text-center">
                    {{ item.lot_order }}
                  </th>
                  <th scope="col" width="300">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <a
                        :id="`custom-${item._id}`"
                        v-b-popover.hover="`custom-${item._id}`"
                        style="color: #0300aa"
                        @click="SearchCode(item.postnumber)"
                        >{{ item.postnumber }}</a
                      >
                      <b-popover
                        :id="`custom-${item._id}`"
                        :target="`custom-${item._id}`"
                        placement="right"
                        triggers="hover"
                        custom-class="custom-popover-content"
                      >
                        <template #default>
                          <div class="p-1 table mt-1">
                            <table width="100%">
                              <tr
                                style="
                                  background-color: #29358a !important;
                                  color: white;
                                "
                              >
                                <th>{{ $t("orderList") }}</th>
                                <th>{{ $t("key-22") }}</th>
                                <th>{{ $t("remarks") }}</th>
                              </tr>
                              <tr class="table-secondary">
                                <td>
                                  <b-link
                                    v-if="item.order_id"
                                    :to="{
                                      name: 'admin-order-detail',
                                      params: { id: item.order_id },
                                    }"
                                    target="_blank"
                                  >
                                    ORD-{{ item.order_text }}
                                  </b-link>
                                  <small v-else>-</small>
                                </td>
                                <td>{{ item.tag_id ? item.tag_id : "-" }}</td>
                                <td>{{ item.remark ? item.remark : "-" }}</td>
                              </tr>
                            </table>
                          </div>
                        </template>
                      </b-popover>
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex mr-1"
                          style="overflow: auto; max-width: 120px"
                        >
                          <span
                            v-for="imageOrAll in item.path_img"
                            :key="imageOrAll"
                            class="preview-image-container mr-1 d-flex justify-content-center"
                            style="height: 30px"
                          >
                            <!-- <img
                              :src="imageOrAll"
                              alt="Preview"
                              class=""

                              @click="showPicture(imageOrAll)"
                            > -->
                            <img
                              :src="GetImg('postnumber_img', imageOrAll)"
                              alt="Preview"
                              class=""
                              @click="showPicture(imageOrAll)"
                            />
                            <div class="preview-image-overlays p-0">
                              <i
                                class="fas fa-times-circle cursor-pointer"
                                @click="removeImage(imageOrAll, item._id)"
                              />
                            </div>

                            <vue-image-lightbox-carousel
                              ref="lightbox"
                              :show="showLightbox"
                              :images="images"
                              :show-caption="false"
                              @close="closeBox()"
                            />
                          </span>
                        </div>
                        <span
                          class="input-file-containers cursor-pointer"
                          style="
                            padding: 2px;
                            position: relative;
                            cursor: pointer;
                          "
                        >
                          <i class="fal fa-plus cursor-pointer text-danger" />
                          <input
                            id="img-file"
                            ref="fileInput"
                            type="file"
                            class="input-file"
                            accept="image/*"
                            multiple
                            @change="handleFileChanges($event, item._id)"
                          />
                        </span>
                      </div>
                      <!-- <span
                      v-for="imageOrAll in item.path_img"
                      :key="imageOrAll"
                      class="od-pc-offer-price-common "
                    >
                      <img
                        :src="imageOrAll"
                        accept=".jpg, .png, .jpeg"
                        alt="รูปภาพ"
                        class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                      >

                    </span> -->
                    </div>
                  </th>
                  <!-- <th scope="col">
                    {{ item.keyword ? item.keyword : '' }}
                  </th> -->
                  <th scope="col">
                    {{ checkwarehouse(item.come_chinesewarehouse) }}
                  </th>
                  <th scope="col">
                    {{ checkwarehouse(item.out_chinesewarehouse) }}
                  </th>
                  <th scope="col">
                    {{ checkwarehouse(item.come_thaiwarehouse) }}
                  </th>

                  <th scope="col">
                    {{ item.username }}
                    <span v-if="item.user_member" class="text-danger"
                      >[{{ item.user_member }}]</span
                    >
                  </th>
                  <th scope="col" class="text-center">
                    {{ Commas(item.product_amount) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ Commas(item.weight * item.product_amount) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ Commas(item.width) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ Commas(item.height) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ Commas(item.long) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ CommasCue(item.charge_cue * item.product_amount) }}
                  </th>
                  <th scope="col" class="text-center">
                    <span style="font-size: 11px">
                      {{
                        item.product_type ? item.product_type.pro_initial : "-"
                      }}
                    </span>
                  </th>
                  <th scope="col" class="text-center">
                    {{ item.collocate_wh_name ? item.collocate_wh_name : "-" }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkprice(item.product_inspection_fee) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkprice(item.re_wrap_fee) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkprice(item.shipping_fee) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkprice(item.other) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkprice(item.packaging_fee) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkcharge_per(item, item.charge_per_kilo) }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ checkcharge_per(item, item.charge_per_cue) }}
                  </th>
                </tr>
              </tbody>

              <tfoot class="text-center" style="background-color: #ebeff2">
                <tr>
                  <th colspan="7">
                    <!-- {{ $t('total') }} -->
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countproducts()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countweight()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countwidth()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countheight()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countproductLong()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ CommasCue(countquantity()) }}
                  </th>
                  <th scope="col" />
                  <th scope="col" />
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countinspection()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(countwrap()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutshipping()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutother()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutpackaging()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutkilo()) }}
                  </th>
                  <th scope="col" style="background-color: #ffa7a3 !important">
                    {{ Commas(conutcue()) }}
                  </th>
                </tr>
              </tfoot>
            </table>

            <div
              class="d-flex flex-wrap align-items-center justify-content-start p-2"
            >
              <!-- <span class="czx ctx-yellow">
                {{ $t('checkGoods') }}
              </span> -->
              <span class="czx ctx-green">
                {{ $t("checkOrder") }}
              </span>
              <span class="czx ctx-orange">
                {{ $t("key-8") }}
              </span>
              <span class="czx ctx-red">
                {{ $t("noPrice") }}
              </span>
              <span class="czx ctx-purple">
                {{ $t("claim") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BFormDatepicker,
  BCollapse,
  VBToggle,
  BFormCheckbox,
  BFormRadio,
  VBTooltip,
  BInputGroupPrepend,
  BPopover,
  BLink,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import moment from "moment-timezone";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BFormDatepicker,
    BCollapse,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BInputGroupPrepend,
    VueImageLightboxCarousel,
    BPopover,
    BLink,
  },
  directives: {
    Ripple,
    // 'b-popover': VBPopover,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isChecked: { key: "checkbox" },
      Checked: { key: "checkbox" },
      message: "",
      customerCodeList: [],
      customerCode: null,
      selectPrice: 1,
      discount: null,
      amountExport: null,
      transportType: "car",
      remark: null,
      name: null,
      fullname: null,
      receivedDate: null,
      woodenCrate: 0,
      checkProduct: 0,
      productWeight: null,
      productHeight: null,
      productWidth: null,
      productLong: null,
      checkProductPrice: null,
      res_quantity: null,
      isActive: true,
      required,
      filepath: [],
      iconClass: "fal fa-copy text-primary cursor-pointer",
      iconClasscustomer: "fal fa-copy text-primary cursor-pointer",
      selectedFiles: [],
      previewImages: [],
      uploadedImages: [],
      orderImages: [],
      fieldsRelevant: [
        { key: "ship", label: "วันที่ทำรายการ", thStyle: { width: "20%" } },
        { key: "kg", label: "รายละเอียด", thStyle: { width: "60%" } },
        { key: "amount", label: "จำนวน", thStyle: { width: "20%" } },
      ],

      productlist: [
        { text: "A", value: 1 },
        { text: "B", value: 2 },
        { text: "C", value: 3 },
        { text: "D", value: 4 },
        { text: "D1", value: 5 },
        { text: "S", value: 6 },
      ],
      ID: this.$route.params.id,
      items: [],
      status: { text: "เข้าโกดังจีน", value: 1 },
      statuslist: [
        { text: "เข้าโกดังจีน", value: 1 },
        { text: "ออกโกดังจีน", value: 2 },
        { text: "เข้าโกดังไทย", value: 3 },
        // { text: 'ออกโกดังไทย', value: 4 },
      ],
      image: [],
      Preview: [],
      arr_img: [],
      viewfiles: [],
      parcelCode: null,
      orderList: null,
      ordernumber: null,
      amount: null,
      productType: null,
      transPrice: null,
      newWrap: null,
      woodenPrice: null,
      other: null,
      filesProducts: [],
      selectFiles: null,
      remarkCode: null,
      filename: [],
      orderget: [],
      arr_parcelCode: [],
      Orderall: [],
      postnumber: [],
      num_products: [],
      username: [],
      note: [],
      ProductTypeitem: [],
      charge_per_cue: null,
      charge_per_kilo: null,
      charge_per_save: null,
      checkedCount: 0,
      OrderList: {},
      checkedparcelCode: 0,
      filesp: null,
      orderFiles: null,
      visible: false,
      amount_discount: 0,
      imagePreviewUrls: "",
      customerlist: [],
      checkagents: false,
      customerAgentList: [],
      customerAgent: null,
      customerAgentAll: null,
    };
  },

  async mounted() {
    this.receivedDate = new Date();
    await this.Getdata();
    await this.getUserData();
    this.getOrderall();
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    checkInput() {
      if (/\s/.test(this.parcelCode) || /\t/.test(this.parcelCode)) {
        this.parcelCode = this.parcelCode.replace(/\s/g, "").replace(/\t/g, "");
      }
    },
    handleCopy() {
      this.iconClass = "fal fa-clipboard-check text-success";

      setTimeout(() => {
        this.iconClass = "fal fa-copy";
      }, 3500);
    },
    handleCopycustomer() {
      this.iconClasscustomer = "fal fa-clipboard-check text-success";

      setTimeout(() => {
        this.iconClasscustomer = "fal fa-copy";
      }, 3500);
    },
    async Getdata() {
      try {
        const params = {
          ID: this.ID,
        };
        const { data: res } = await this.$http.get("/Lotorder/getLotOne", {
          params,
        });
        this.items = res.data;
        this.amount = 1;
        this.previewImages = res.log_img;
        this.viewfiles = res.log_file;
        this.delete_path = [];
        this.delete_pathfile = [];
      } catch (error) {
        console.log(error);
      }
    },
    async getOrderall() {
      try {
        const params = {
          ID: this.items._id,
        };
        // console.log(params)
        const { data: res } = await this.$http.get("/Lotorder/getData", {
          params,
        });

        res.forEach((ele) => {
          // eslint-disable-next-line no-param-reassign
          // ele.path_file = []
          // ele.path_img = []
          ele.Checked = { key: "checkbox" };
        });
        this.Orderall = res;
        this.ordernumber =
          this.Orderall.reduce((acc, total) => acc + total.product_amount, 0) +
          1;
        // res.forEach(ele => {
        //   const orderData = {
        //     Orderall: ele.data,
        //     username: ele.username,
        //     note: ele.note,
        //     postnumber: ele.data.postnumber,
        //     IDorderall: ele.IDorderall,
        //     Checked: { key: 'checkbox' },
        //   }
        //   this.Orderall.push(orderData)
        // })
        // this.getimgpost()
      } catch (e) {
        console.log(e);
      }
    },
    getimgpost() {
      if (this.Orderall.length) {
        const Id = [];
        this.Orderall.forEach((ele) => {
          Id.push(ele._id);
        });
        const params = {
          id: Id,
        };
        this.$http
          .get("/Lotorder/postnumber/getDataIMG", { params })
          .then((response) => {
            response.data.forEach((path_img, index) => {
              this.Orderall[index].path_img = path_img.path_img;
              this.Orderall[index].path_file = path_img.path_file;
            });
          })
          .catch((err) => console.log(err));
      }
    },
    SubmitOrder() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.customerCode) {
            this.showToast("danger", "กรุณากรอกข้อมูลให้ครบถ้วน");
            return;
          }
          // if (this.checkagents && (!this.customerAgent)) {
          //   this.showToast('danger', 'กรุณากรอกข้อมูลให้ครบถ้วน')
          //   return
          // }
          this.res_quantity = null;
          this.amount_discount = 0;
          if (this.selectPrice === 1) {
            this.res_quantity = this.charge_per_kilo;
          } else {
            this.res_quantity = this.charge_per_cue;
          }
          const obj = {
            _id: this.IDorder,
            username: this.customerCode.username,
            uid: this.customerCode.id,
            lot_id: this.items._id,
            lot_name: this.items.name_lot,
            lot_number: this.items.number_lot,
            IDorderall: this.getID,
            postnumber: this.parcelCode,
            remark: this.remarkCode,
            packaging_fee: this.woodenPrice,
            product_inspection_fee: this.checkProductPrice,
            shipping_fee: this.transPrice,
            re_wrap_fee: this.newWrap,
            packbox: this.woodenCrate,
            checkproduct: this.checkProduct,
            tag_id: this.orderList,
            come_chinesewarehouse: this.come_chinesewarehouse,
            out_chinesewarehouse: this.out_chinesewarehouse,
            come_thaiwarehouse: this.come_thaiwarehouse,
            out_thaiwarehouse: this.out_thaiwarehouse,
            lot_order: this.ordernumber,
            lot_show: 1,
            weight: this.productWeight,
            height: this.productHeight,
            width: this.productWidth,
            long: this.productLong,
            other: this.other,
            product_type: this.productType,
            charge_per_save: this.CommasUp(this.res_quantity),
            charge_per_cue: this.CommasUp(this.charge_per_cue),
            charge_per_kilo: this.CommasUp(this.charge_per_kilo),
            path_img: this.orderImages,
            path_file: this.filesProducts,
            product_amount: this.amount,
            transportType: this.items.transportType,
            discount: this.items.discount,
            amount_discount: 0,
            charge_cue: this.CommasCue(
              (this.productWidth * this.productLong * this.productHeight) /
                1000000
            ),
            user_member_check: false,
            user_member: null,
            user_member_id: null,
          };
          if (this.checkagents) {
            obj.user_member_check = this.checkagents;
            obj.user_member = this.customerAgent
              ? this.customerAgent.user_member
              : null;
            obj.user_member_id = this.customerAgent
              ? this.customerAgent._id
              : null;
          }
          // eslint-disable-next-line eqeqeq
          if (obj.charge_per_kilo == obj.charge_per_save) {
            if (this.items.discount) {
              obj.amount_discount =
                (obj.charge_per_kilo * (100 - this.items.discount)) / 100;
            } else {
              obj.amount_discount = 0;
            }
            // eslint-disable-next-line eqeqeq
          } else if (obj.charge_per_cue == obj.charge_per_save) {
            if (this.items.discount) {
              obj.amount_discount =
                (obj.charge_per_cue * (100 - this.items.discount)) / 100;
            } else {
              obj.amount_discount = 0;
            }
          }
          // console.log(this.items.transportType)
          // this.$http.post('/Lotorder/UpdateOrder', obj)
          this.$http
            .post("/Lotorder/updatepostnumber", obj)
            .then((response) => {
              if (response.data.success) {
                this.getOrderall();
                this.Success(
                  `บันทึกพัสดุลงในล็อตเรียบร้อย อยู่ลำดับที่ ${this.ordernumber}`
                );
                this.visible = false;
                this.parcelCode = null;
                this.arr_parcelCode = [];
                this.getID = null;
                this.IDorder = null;
                this.ordernumber = null;
                this.remarkCode = null;
                this.woodenPrice = null;
                this.checkProductPrice = null;
                this.transPrice = null;
                this.newWrap = null;
                this.checkProduct = false;
                this.amount = 1;
                this.orderList = null;
                this.customerCode = null;
                this.woodenCrate = false;
                this.other = null;
                this.productWeight = null;
                this.productHeight = null;
                this.productWidth = null;
                this.productLong = null;
                this.productType = null;
                this.charge_per_save = null;
                this.charge_per_kilo = null;
                this.charge_per_cue = null;
                this.orderFiles = null;
                this.orderImages = [];
                this.filesProducts = [];
                this.filesp = null;
                this.selectPrice = 1;
                this.ProductTypeitem = [];
                this.checkagents = false;
                this.customerAgentList = [];
                this.customerAgent = null;
                this.customerAgentAll = null;
              } else {
                this.SwalError(error.response.data.data);
              }
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
        } else {
          this.showToast("danger", "กรุณากรอกข้อมูลให้ครบถ้วน");
        }
      });
    },
    toggleChecked(check) {
      if (check) {
        this.checkedCount += 1;
      } else {
        this.checkedCount -= 1;
      }
      // const selectedOrder = this.Orderall[index]
    },
    toggleCheckedall(Checked) {
      if (Checked === true) {
        this.isChecked = this.Orderall.map(() => this.Checked);
        this.checkedCount = this.isChecked.length;
      } else {
        this.isChecked = this.Orderall.map(() => this.Checked);
        this.checkedCount = 0;
      }
    },
    checkedsaves(value) {
      if (value > 0) {
        return `(${value})`;
      }
      return "";
    },
    checkedcolor(item) {
      if (item.packbox && item.checkproduct) {
        return "yellow";
      }
      if (item.checkproduct) {
        return "green";
      }
      if (item.packbox) {
        return "orange";
      }
      if (item.charge_per_save === 0) {
        return "red";
      }
      if (item.claim_status) {
        return "purple";
      }
      return "#ffffff";
    },
    submitCode() {
      this.$bvModal
        .msgBoxConfirm(
          `บันทึกเวลา ${this.status.text} ${this.checkedCount} รายการ?`,
          {
            title: "ยืนยันข้อมูล",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const checkedItems = this.Orderall.filter(
              (item, index) => this.isChecked[index]
            );
            const idorder = [];
            // eslint-disable-next-line no-underscore-dangle
            checkedItems.forEach((ele) => {
              idorder.push(ele._id);
            });
            // console.log() idorder.push(ele.IDorderall)
            // const come_chines = ele.Orderall.come_chinesewarehouse
            const formattedDate = moment(this.receivedDate).format(
              "DD/MM/YYYY"
            );
            if (this.status.value === 1) {
              this.come_chinese = formattedDate;
            } else if (this.status.value === 2) {
              this.out_chines = formattedDate;
            } else if (this.status.value === 3) {
              this.come_thai = formattedDate;
            } else if (this.status.value === 4) {
              this.out_thai = formattedDate;
            }
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              Idlot: this.items._id,
              number_lot: this.items.number_lot,
              ID: idorder,
              come_chinesewarehouse: this.come_chinese,
              out_chinesewarehouse: this.out_chines,
              come_thaiwarehouse: this.come_thai,
              out_thaiwarehouse: this.out_thai,
            };
            this.$http.post("/Lotorder/updatetime", obj).then(() => {
              this.getOrderall();
              this.Success(`อัพเดทเวลา ${this.status.text} เรียบร้อย`);
              this.isChecked = { key: "checkbox" };
              this.Checked = { key: "checkbox" };
              this.checkedCount = 0;
              this.come_chinese = null;
              this.out_chines = null;
              this.come_thai = null;
              this.out_thai = null;
            });
          }
        });
    },
    resettime() {
      this.$bvModal
        .msgBoxConfirm(
          `รีเซ็ตเวลา ${this.status.text} ${this.checkedCount} รายการ?`,
          {
            title: "ยืนยันข้อมูล",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const checkedItems = this.Orderall.filter(
              (item, index) => this.isChecked[index]
            );
            const idorder = [];
            // eslint-disable-next-line no-underscore-dangle
            checkedItems.forEach((ele) => {
              idorder.push(ele._id);
            });
            // console.log()
            // const come_chines = ele.Orderall.come_chinesewarehouse
            if (this.status.value === 1) {
              this.come_chinese = "reset";
            } else if (this.status.value === 2) {
              this.out_chines = "reset";
            } else if (this.status.value === 3) {
              this.come_thai = "reset";
            } else if (this.status.value === 4) {
              this.out_thai = "reset";
            }
            const obj = {
              ID: idorder,
              come_chinesewarehouse: this.come_chinese,
              out_chinesewarehouse: this.out_chines,
              come_thaiwarehouse: this.come_thai,
              out_thaiwarehouse: this.out_thai,
              Idlot: this.items._id,
            };
            this.$http.post("/Lotorder/updatetime", obj).then(() => {
              this.getOrderall();
              this.Success(`รีเซ็ตเวลา ${this.status.text} เรียบร้อย`);
              this.isChecked = { key: "checkbox" };
              this.Checked = { key: "checkbox" };
              this.checkedCount = 0;
            });
          }
        });
    },
    removeImage(index, ID) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบรูปสินค้าใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const obj = {
              _id: ID,
              path_img: index,
            };
            this.$http
              .post("/Tracking/remove", obj)
              .then(() => {
                this.Success("ลบรูปสินค้าเรียบร้อย");
                this.getOrderall();
              })
              .catch((error) => {
                this.SwalError(error.response.data.message);
              });
            // this.delete_path = []
            // const indexToRemove = this.items.path_file.indexOf(index)
            // if (indexToRemove !== -1) {
            //   this.items.path_file.splice(indexToRemove, 1)
            //   this.items.path_file = null
            // }
          }
        });
      // console.log(url)
      // console.log(index)
      // this.imagePreviewUrls = this.imagePreviewUrls.filter(item => item !== url)
    },
    handleFileChanges(event, ID) {
      const { files } = event.target;
      // console.log(files)
      // this.imagePreviewUrls = []
      localStorage.setItem(
        "imagePreviewUrls",
        JSON.stringify(this.imagePreviewUrls)
      );
      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviewUrls = e.target.result;
          const obj = {
            _id: ID,
            image: this.imagePreviewUrls,
          };
          this.$http
            .post("/Tracking/updateimg", obj)
            .then(() => {
              this.getOrderall();
              this.Success("เพิ่มรูปสินค้าเรียบร้อย");
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
          // this.imagePreviewUrls.push(e.target.result)
          // console.log(this.imagePreviewUrls)
        };
        reader.readAsDataURL(files[i]);
      }
    },
    async getUserData() {
      try {
        const { data: res } = await this.$http.get(
          "/users/customer/active/lot"
        );
        this.customerCodeList = res;
        // console.log(this.customerCode)
      } catch (e) {
        console.log(e);
      }
    },
    async SearchCode(parcelCode) {
      if (parcelCode) {
        this.checkedparcelCode = 1;
        this.parcelCode = parcelCode;
        const obj = {
          parcelCode,
        };
        this.visible = true;
        const { data: res } = await this.$http.get("/Lotorder/getOrder", {
          params: obj,
        });
        if (res.success) {
          const targetCard = document.getElementById("collapse-1");
          if (targetCard) {
            targetCard.scrollIntoView({ behavior: "smooth" });
          }
          // .includes(parcelCode)
          this.arr_parcelCode = res.data;
          // console.log(this.arr_parcelCode)
          if (res.OrderList) {
            this.OrderList = res.OrderList;
            // eslint-disable-next-line
            const FindShop = this.OrderList.shops.find(
              (item) => item._id == this.arr_parcelCode.shop_id
            );
            // this.amount = FindShop.products.length
            if (this.OrderList.delivery_type !== this.items.transportType) {
              this.Alert(
                false,
                `ลูกค้าระบุขนส่ง ${this.getType(this.OrderList.delivery_type)}`
              );
            }
            this.customerCode = {
              username: this.OrderList.username,
              id: this.OrderList.uid,
            };
            // eslint-disable-next-line no-underscore-dangle
            this.getID = this.OrderList._id;
            // this.orderList = FindShop.tag_id // รายการสั่งซื้อ
          }
          if (this.arr_parcelCode.checkproduct && this.arr_parcelCode.packbox) {
            this.checkAlert(false, this.$t("checkGoods"));
          } else if (this.arr_parcelCode.packbox) {
            this.checkAlert(false, this.$t("woodPacking"));
          } else if (this.arr_parcelCode.checkproduct) {
            this.checkAlert(false, this.$t("checkOrder"));
          }
          if (this.arr_parcelCode.transportType !== this.items.transportType) {
            this.Alert(
              false,
              `ลูกค้าระบุขนส่ง ${this.getType(
                this.arr_parcelCode.transportType
              )}`
            );
          }
          this.customerCode = {
            username: this.arr_parcelCode.username,
            id: this.arr_parcelCode.uid,
          };
          this.orderList = this.arr_parcelCode.tag_id;
          this.remarkCode = this.arr_parcelCode.remark; // หมายเหตุ
          this.woodenPrice = this.arr_parcelCode.packaging_fee; // ราคาตีลัง
          this.checkProductPrice = this.arr_parcelCode.product_inspection_fee; // ราคาเช็คสินค้า
          this.transPrice = this.arr_parcelCode.shipping_fee; // ขนส่ง
          this.newWrap = this.arr_parcelCode.re_wrap_fee; // ห่อใหม่
          this.woodenCrate = this.arr_parcelCode.packbox; // ตีลังไม้
          this.checkProduct = this.arr_parcelCode.checkproduct; // เช็คสินค้า
          // console.debug(this.arr_parcelCode)
          this.amount = this.arr_parcelCode.product_amount;
          // this.amount = 1
          this.come_chinesewarehouse =
            this.arr_parcelCode.come_chinesewarehouse;
          this.out_chinesewarehouse = this.arr_parcelCode.out_chinesewarehouse;
          this.come_thaiwarehouse = this.arr_parcelCode.come_thaiwarehouse;
          this.out_thaiwarehouse = this.arr_parcelCode.out_thaiwarehouse;
          // eslint-disable-next-line no-underscore-dangle
          this.IDorder = this.arr_parcelCode._id;
          this.ordernumber = this.arr_parcelCode.lot_order
            ? this.arr_parcelCode.lot_order
            : this.Orderall.reduce(
                (acc, total) => acc + total.product_amount,
                0
              ) + 1; // ลำดับที่
          this.productWeight = this.arr_parcelCode.weight; // น้ำหนัก
          this.productHeight = this.arr_parcelCode.height;
          this.productWidth = this.arr_parcelCode.width;
          this.productLong = this.arr_parcelCode.long;
          this.other = this.arr_parcelCode.other;
          this.productType = this.arr_parcelCode.product_type;
          this.charge_per_save = this.arr_parcelCode.charge_per_save;
          this.charge_per_kilo = this.arr_parcelCode.charge_per_kilo;
          this.charge_per_cue = this.arr_parcelCode.charge_per_cue;
          this.orderImages = this.arr_parcelCode.path_img;
          this.filesProducts = this.arr_parcelCode.path_file;
          this.customerAgentAll = {
            user_member: this.arr_parcelCode.user_member,
            user_member_check: this.arr_parcelCode.user_member_check,
            user_member_id: this.arr_parcelCode.user_member_id,
            product_type: [],
          };
          this.checkedprice();
          if (this.charge_per_save === this.charge_per_kilo) {
            this.selectPrice = 1;
          } else if (this.charge_per_save === this.charge_per_cue) {
            this.selectPrice = 2;
          }
          if (this.arr_parcelCode.user_member_check) {
            this.customerAgent = {
              _id: this.arr_parcelCode.user_member_id,
              user_member: this.arr_parcelCode.user_member,
            };
          }
          this.getProduct(res.data.uid);
        } else {
          this.ordernumber = this.Orderall.length + 1;
          this.checkedparcelCode = 1;
          this.getID = null;
          this.IDorder = null;
          this.amount = 1;
          this.arr_parcelCode = [];
          this.remarkCode = null;
          this.woodenPrice = null;
          this.checkProductPrice = null;
          this.transPrice = null;
          this.newWrap = null;
          this.checkProduct = null;
          this.orderList = null;
          this.customerCode = null;
          this.woodenCrate = null;
          this.other = null;
          this.productWeight = null;
          this.productHeight = null;
          this.productWidth = null;
          this.productLong = null;
          this.productType = null;
          this.ProductTypeitem = [];
          this.checkagents = false;
          this.customerAgentList = [];
          this.customerAgent = null;
          this.customerAgentAll = null;
          // this.SwalError(res.message)
        }
      } else {
        this.getID = null;
        this.IDorder = null;
        this.checkedparcelCode = 0;
        this.remarkCode = null;
        this.woodenPrice = null;
        this.checkProductPrice = null;
        this.transPrice = null;
        this.newWrap = null;
        this.checkProduct = null;
        this.orderList = null;
        this.customerCode = null;
        this.woodenCrate = null;
        this.other = null;
        this.productWeight = null;
        this.productHeight = null;
        this.productWidth = null;
        this.productLong = null;
        this.productType = null;
        this.ProductTypeitem = [];
        this.checkagents = false;
        this.customerAgentList = [];
        this.customerAgent = null;
        this.customerAgentAll = null;
      }
    },
    getType(type) {
      if (type === 1) {
        return "รถ";
      }
      return "เรือ";
    },
    previewImage(event) {
      this.selectedFiles = event.target.files;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const filepath = this.selectedFiles[i];

        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImages.push({
            img_base64: e.target.result,
            key_path: null,
            delete_path: true,
          });
        };
        reader.readAsDataURL(filepath);
      }
    },
    orderImage(event) {
      try {
        this.orderFiles = event.target.files;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.orderFiles.length; i++) {
          const filepath = this.orderFiles[i];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.orderImages.push(e.target.result);
          };
          reader.readAsDataURL(filepath);
        }
      } catch (e) {
        console.debug(e);
      }
    },
    fileProduct(event) {
      this.filesp = event.target.files;
      this.filesProducts = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.filesp.length; i++) {
        const filepath = this.filesp[i];

        const reader = new FileReader();
        reader.onload = (e) => {
          this.filesProducts.push(e.target.result);
        };
        reader.readAsDataURL(filepath);
      }
    },
    checkedprice() {
      if (this.productWeight && this.productType.pro_kilo && this.amount) {
        const sum =
          this.productWeight * this.productType.pro_kilo * this.amount;
        this.charge_per_kilo = this.CommasUp(sum);
        if (this.charge_per_kilo > this.charge_per_cue) {
          this.selectPrice = 1;
        } else if (this.charge_per_cue > 0) {
          this.selectPrice = 2;
        }
      }
    },
     checkedpricereq() {
      if (
        this.productWidth &&
        this.productLong &&
        this.productHeight &&
        this.productType.pro_queue &&
        this.amount
      ) {
        const req =  this.CommasCue(
          ((Number(this.productWidth) * Number(this.productLong) * Number(this.productHeight)) /
            1000000)
        ) * Number(this.productType.pro_queue)
        this.selectPrice = 2;
        this.charge_per_cue = this.CommasUp(req * this.amount);
        if (this.charge_per_cue > this.charge_per_kilo) {
          this.selectPrice = 2;
        } else if (this.charge_per_kilo > 0) {
          this.selectPrice = 1;
        }
      }
    },
    reitem() {
      this.selectPrice = 1;
      this.charge_per_kilo = 0;
      this.charge_per_cue = 0;
      this.checkedprice();
      this.checkedpricereq();
    },
    checkedfile(filess) {
      const obj = {
        pathfile: filess,
      };
      this.$http
        .post("/Lotorder/view", obj, { responseType: "blob" })
        .then((response) => {
          if (obj.pathfile.endsWith(".xls")) {
            const text = obj.pathfile.split("/");
            const fileName = text[text.length - 1];
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`; // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click();
          }
          if (obj.pathfile.endsWith(".xlsx")) {
            const text = obj.pathfile.split("/");
            const fileName = text[text.length - 1];
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`; // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click();
          }
          if (obj.pathfile.endsWith(".pdf")) {
            const text = obj.pathfile.split("/");
            const fileName = text[text.length - 1];
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`; // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async getProduct(data) {
      const params = {
        uid: data,
      };
      this.$http
        .get("/groupbyuser/show_user", { params })
        .then((response) => {
          if (response.data.success) {
            const Res = response.data.data;
            this.ProductTypeitem = [];
            if (this.OrderList.delivery_type) {
              for (const items of Res.product_type.filter(
                (item) =>
                  item.product_type_class === this.OrderList.delivery_type
              )) {
                this.ProductTypeitem.push({
                  pro_id: items.product_type_id,
                  pro_name: items.product_type_name,
                  pro_class: items.product_type_class,
                  pro_kilo: items.product_type_kilo,
                  pro_queue: items.product_type_queue,
                  pro_initial: items.product_type_initial,
                  pro_name_en: items.product_type_name_en,
                  pro_name_cn: items.product_type_name_cn,
                });
              }
              this.ProductTypeitem.sort((a, b) => {
                if (a.pro_initial < b.pro_initial) {
                  return -1;
                }
                if (a.pro_initial > b.pro_initial) {
                  return 1;
                }
                return 0;
              });
              this.productType = this.ProductTypeitem[0];
            } else if (this.arr_parcelCode.transportType) {
              for (const items of Res.product_type.filter(
                (item) =>
                  item.product_type_class === this.arr_parcelCode.transportType
              )) {
                this.ProductTypeitem.push({
                  pro_id: items.product_type_id,
                  pro_name: items.product_type_name,
                  pro_class: items.product_type_class,
                  pro_kilo: items.product_type_kilo,
                  pro_queue: items.product_type_queue,
                  pro_initial: items.product_type_initial,
                  pro_name_en: items.product_type_name_en,
                  pro_name_cn: items.product_type_name_cn,
                });
              }
              this.ProductTypeitem.sort((a, b) => {
                if (a.pro_initial < b.pro_initial) {
                  return -1;
                }
                if (a.pro_initial > b.pro_initial) {
                  return 1;
                }
                return 0;
              });
              this.productType = this.ProductTypeitem[0];
            } else {
              for (const items of Res.product_type.filter(
                (item) => item.product_type_class === this.items.transportType
              )) {
                this.ProductTypeitem.push({
                  pro_id: items.product_type_id,
                  pro_name: items.product_type_name,
                  pro_class: items.product_type_class,
                  pro_kilo: items.product_type_kilo,
                  pro_queue: items.product_type_queue,
                  pro_initial: items.product_type_initial,
                  pro_name_en: items.product_type_name_en,
                  pro_name_cn: items.product_type_name_cn,
                });
              }
              this.ProductTypeitem.sort((a, b) => {
                if (a.pro_initial < b.pro_initial) {
                  return -1;
                }
                if (a.pro_initial > b.pro_initial) {
                  return 1;
                }
                return 0;
              });
              this.productType = this.ProductTypeitem[0];
            }
          }
          this.checkagent(data);
        })
        .catch((error) => console.log(error));
    },
    checkagent(data) {
      const params = {
        uid: data,
      };
      this.$http
        .get("/Lotorder/AgentUser", { params })
        .then((response) => {
          if (response.data.status) {
            this.checkagents = response.data.status;
            // this.productType = null
            // this.ProductTypeitem = []
            this.AgentUser(data);
          } else {
            this.checkagents = false;
            this.productType = null;
            // this.ProductTypeitem = []
          }
        })
        .catch((err) => {
          this.checkagents = false;
          this.customerAgentList = [];
          this.customerAgent = null;
          // this.ProductTypeitem = []
        });
    },
    AgentUser(data) {
      const params = {
        uid: data,
      };
      this.$http.get("Usermember/GetMemberLot", { params }).then((response) => {
        this.customerAgentList = [];
        // this.customerAgent = null
        this.customerAgentList = response.data.data;
        // this.ProductTypeitem = []
        // this.productType = null
        // if (this.customerAgentAll) {
        //   const index = this.customerAgentList.findIndex(ele => ele.user_member === this.customerAgentAll.user_member)
        //   if (index !== -1) {
        //     this.customerAgent = this.customerAgentList[index]
        //     // this.ProductTypeitem = []
        //     this.productType = this.arr_parcelCode.product_type
        //     // for (const items of this.customerAgent.product_type.filter(item => item.product_type_class === this.arr_parcelCode.transportType)) {
        //     //   this.ProductTypeitem.push({
        //     //     pro_id: items.product_type_id, pro_name: items.product_type_name, pro_class: items.product_type_class, pro_kilo: items.product_type_kilo, pro_queue: items.product_type_queue,
        //     //     pro_initial: items.product_type_initial,
        //     //   })
        //     // }
        //     // this.ProductTypeitem.sort((a, b) => {
        //     //   if (a.pro_name < b.pro_name) {
        //     //     return -1
        //     //   } if (a.pro_name > b.pro_name) {
        //     //     return 1
        //     //   }
        //     //   return 0
        //     // })
        //   }
        // }
      });
    },
    Checkname() {
      if (this.$i18n.locale === "th") {
        return "pro_name";
      }
      if (this.$i18n.locale === "en") {
        return "pro_name_en";
      }
      if (this.$i18n.locale === "cn") {
        return "pro_name_cn";
      }
    },
    AgentProduct(data) {
      if (data) {
        this.ProductTypeitem = [];
        if (this.OrderList.delivery_type) {
          for (const items of data.product_type.filter(
            (item) => item.product_type_class === this.OrderList.delivery_type
          )) {
            this.ProductTypeitem.push({
              pro_id: items.product_type_id,
              pro_name: items.product_type_name,
              pro_class: items.product_type_class,
              pro_kilo: items.product_type_kilo,
              pro_queue: items.product_type_queue,
              pro_initial: items.product_type_initial,
            });
          }
          this.ProductTypeitem.sort((a, b) => {
            if (a.pro_name < b.pro_name) {
              return -1;
            }
            if (a.pro_name > b.pro_name) {
              return 1;
            }
            return 0;
          });
        } else if (this.arr_parcelCode.transportType) {
          for (const items of data.product_type.filter(
            (item) =>
              item.product_type_class === this.arr_parcelCode.transportType
          )) {
            this.ProductTypeitem.push({
              pro_id: items.product_type_id,
              pro_name: items.product_type_name,
              pro_class: items.product_type_class,
              pro_kilo: items.product_type_kilo,
              pro_queue: items.product_type_queue,
              pro_initial: items.product_type_initial,
            });
          }
          this.ProductTypeitem.sort((a, b) => {
            if (a.pro_name < b.pro_name) {
              return -1;
            }
            if (a.pro_name > b.pro_name) {
              return 1;
            }
            return 0;
          });
        } else {
          this.productType = null;
          for (const items of data.product_type.filter(
            (item) => item.product_type_class === this.items.transportType
          )) {
            this.ProductTypeitem.push({
              pro_id: items.product_type_id,
              pro_name: items.product_type_name,
              pro_class: items.product_type_class,
              pro_kilo: items.product_type_kilo,
              pro_queue: items.product_type_queue,
              pro_initial: items.product_type_initial,
            });
          }
          this.ProductTypeitem.sort((a, b) => {
            if (a.pro_name < b.pro_name) {
              return -1;
            }
            if (a.pro_name > b.pro_name) {
              return 1;
            }
            return 0;
          });
        }
      } else {
        this.productType = null;
        this.ProductTypeitem = [];
      }
      // console.debug('Agent',data)
    },
    async printOrder() {
      try {
        window.open(
          `https://api.anan-cargo.com/api/print/pickup/${this.items._id}`
        );
        // window.open(`http://127.0.0.1:4444/api/print/pickup/${this.ID}`)
      } catch (e) {
        console.log(e);
      }
    },
    printLot() {
      const obj = [this.items._id];
      this.$http
        .post("/print/lotall", obj, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);
          window.open(pdfUrl, "_blank");
        });
    },
    checkvalue(value) {
      if (value === 0 || value === null || value === undefined) {
        return "0";
      }
      return value;
    },
    checksave(value) {
      if (value === null || value === undefined) {
        return `${this.$t("key-175")}0`;
      }
      if (value === this.charge_per_kilo) {
        return this.$t("key-175") + value;
      }
      return `${this.$t("key-0")} ${value}`;
    },
    // CommasCue(x) {
    //   if (!x) {
    //     return '0'
    //   }
    //   const parts = x.toString().split('.')
    //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    //   if (parts[1]) {
    //     parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
    //   } else {
    //     parts.push('0000')
    //   }
    //   return parts.join('.')
    // },

    async Submit() {
      // console.debug(this.item)
      const formData = new FormData();
      formData.append("ID", this.items._id);
      formData.append("number_lot", this.items.number_lot);
      formData.append("name_lot", this.items.name_lot);
      if (this.items.remark) {
        formData.append("remark", this.items.remark);
      }

      formData.append("transportType", this.items.transportType);
      formData.append("discount", this.items.discount);
      formData.append("amount_out", this.items.amount_out);
      // eslint-disable-next-line no-restricted-syntax
      for (const imageObject of this.previewImages) {
        formData.append("lot_img", imageObject.img_base64);
        formData.append("key_path", imageObject.key_path);
        formData.append("delete_path", imageObject.delete_path);
        // console.log(this.previewImages)
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.viewfiles) {
        formData.append("file", file.pathname);
        formData.append("filename", file.namefile);
        formData.append("key_filepath", file.key_filepath);
        formData.append("delete_pathfile", file.delete_pathfile);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const del of this.delete_path) {
        formData.append("delfile", del.key_path);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const dele of this.delete_pathfile) {
        formData.append("delefiles", dele.key_filepath);
      }

      this.$http
        .post("/Lotorder/update_lot", formData)
        .then(() => {
          this.Getdata();
          this.Success("บันทึกล๊อตสินค้าสำเร็จ");
        })
        .catch((error) => {
          this.SwalError(error.response.data.message);
        });
    },
    previewShow(event) {
      this.filepath = event.target.files;
      this.viewfiles = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.filepath.length; i++) {
        const filepath = this.filepath[i];
        const filename = this.filepath[i].name;
        const reader = new FileReader();
        this.viewfiles.push({
          pathname: filepath,
          namefile: filename,
          key_filepath: null,
          delete_pathfile: true,
        });
        reader.readAsDataURL(filepath);
      }
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? "success" : "error"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    checkprice(value) {
      if (value === 0) {
        return "-";
      }
      return this.Commas(value);
    },
    countproducts() {
      return this.Orderall.reduce(
        (total, item) => total + item.product_amount,
        0
      );
    },
    countweight() {
      return this.Orderall.reduce(
        (total, item) => total + item.weight * item.product_amount,
        0
      );
    },
    countwidth() {
      return this.Orderall.reduce((total, item) => total + item.width, 0);
    },
    countheight() {
      return this.Orderall.reduce((total, item) => total + item.height, 0);
    },
    countproductLong() {
      return this.Orderall.reduce((total, item) => total + item.long, 0);
    },
    countquantity() {
      return this.Orderall.reduce(
        (total, item) => total + item.charge_cue * item.product_amount,
        0
      );
    },
    countinspection() {
      return this.Orderall.reduce(
        (total, item) => total + item.product_inspection_fee,
        0
      );
    },
    countwrap() {
      return this.Orderall.reduce((total, item) => total + item.re_wrap_fee, 0);
    },
    conutshipping() {
      return this.Orderall.reduce(
        (total, item) => total + item.shipping_fee,
        0
      );
    },
    conutother() {
      return this.Orderall.reduce((total, item) => total + item.other, 0);
    },
    conutpackaging() {
      return this.Orderall.reduce(
        (total, item) => total + item.packaging_fee,
        0
      );
    },
    conutkilo() {
      const arrkilo = [];
      this.Orderall.forEach((ele) => {
        if (ele.charge_per_kilo === ele.charge_per_save) {
          arrkilo.push(ele.charge_per_kilo);
        }
      });
      return arrkilo.reduce((total, item) => total + item, 0);
    },
    conutcue() {
      const arrcue = [];
      this.Orderall.forEach((ele) => {
        if (ele.charge_per_cue === ele.charge_per_save) {
          arrcue.push(ele.charge_per_cue);
        }
      });
      return arrcue.reduce((total, item) => total + item, 0);
    },
    checkwarehouse(value) {
      if (value === null || value === undefined) {
        return "-";
      }
      return value;
    },
    checkvalues(values) {
      if (values.product_type === null) {
        return "-";
      }
      return values.product_type[0].label;
    },
    checkcharge_per(values, kilo) {
      if (values.charge_per_save !== kilo) {
        return "-";
      }
      return this.Commas(values.charge_per_save);
    },
    checkAlert(status, title, mes) {
      this.$swal({
        icon: `${status ? "success" : "warning"}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    deleteImageorder(index) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบรูปสินค้าใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // this.delete_path = []

            const indexToRemove = this.orderImages.indexOf(index);
            if (indexToRemove !== -1) {
              this.orderImages.splice(indexToRemove, 1);
            }
          } else {
            console.log("เกิดข้อมูลผิดพลาด");
          }
        });
    },
    deleteorderfile(index) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบรูปสินค้าใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // this.delete_path = []
            const indexToRemove = this.filesProducts.indexOf(index);
            if (indexToRemove !== -1) {
              this.filesProducts.splice(indexToRemove, 1);
              this.filesProducts = null;
            }
          } else {
            console.log("เกิดข้อมูลผิดพลาด");
          }
        });
    },
    deleteImage(index) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบรูปภาพใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // this.delete_path = []
            const indexToRemove = this.previewImages.indexOf(index);
            if (indexToRemove !== -1) {
              this.previewImages.splice(indexToRemove, 1);
              this.delete_path.push(index);
            }
          } else {
            console.log("เกิดข้อมูลผิดพลาด");
          }
        });
    },
    deleteFile(index) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบรูปภาพใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // this.delete_path = []
            const indexToRemove = this.viewfiles.indexOf(index);
            if (indexToRemove !== -1) {
              this.viewfiles.splice(indexToRemove, 1);
              this.delete_pathfile.push(index);
            }
          } else {
            console.log("เกิดข้อมูลผิดพลาด");
          }
        });
    },
    DeleteData(item) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบออกจากล็อตใช่หรือไม่?", {
          title: "ยืนยันการลบออกจากล็อต",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const obj = {
              lot_show: 0,
              discount: 0,
              IDpost: item,
            };
            this.$http
              .post("/Lotorder/updatafile", obj)
              .then(() => {
                this.getOrderall();
                this.Success("ลบพัสดุออกจากล็อตเรียบร้อย");
              })
              .catch((error) => {
                this.SwalError(error.response.data.message);
              });
          }
        });
    },
    Orderdel() {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการล้างข้อมูลใช่หรือไม่?", {
          title: "ยืนยันการล้างข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.parcelCode = null;
            this.remarkCode = null;
            this.woodenPrice = null;
            this.checkProductPrice = null;
            this.transPrice = null;
            this.newWrap = null;
            this.checkProduct = null;
            this.amount = null;
            this.orderList = null;
            this.customerCode = null;
            this.woodenCrate = null;
            this.productWeight = null;
            this.productHeight = null;
            this.productWidth = null;
            this.productLong = null;
            this.other = null;
            this.ordernumber = null;
            this.productType = null;
            this.charge_per_save = null;
            this.charge_per_kilo = null;
            this.charge_per_cue = null;
            this.selectPrice = 1;
            this.orderImages = [];
            this.filesProducts = [];
          }
        });
    },
    saveParcel() {
      this.$refs.addRules.validate().then((success) => {
        if (success) {
          const Obj = {
            fullname: this.name,
            tel: this.phone,
            address: this.address,
            province: this.province,
            district: this.district,
            subdistrict: this.subdistrict,
            postalcode: this.postalcode,
          };
          this.Address.push(Obj);
          this.$bvModal.hide("add-addr");
        }
      });
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: this.GetImg("postnumber_img", picture) };
      this.images.push(img);
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    showAdd() {
      this.$bvModal.show("add-addr");
    },
    showModal() {
      this.name = null;
      this.phone = null;
      this.address = null;
      this.province = null;
      this.district = null;
      this.subdistrict = null;
      this.postalcode = null;
      this.$bvModal.show("modal-1");
    },
    showToast(variant, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: "BellIcon",
          title: message,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
tbody tr th {
  font-weight: 400 !important;
}
.custom-popover-content {
  max-width: none !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}
</style>
